import React from 'react';
import LandingPageView2 from 'views/LandingPageView2';
import Main from 'layouts/Main';
import WithLandingPageLayout from 'WithLandingPageLayout';
import { Helmet } from 'react-helmet';

// DATA

const title = 'Save on Azure Storage Pricing';
const content = {
  hero: {
    title: "Use WindRate to save up to 60% on",
    emphasized: "Azure Storage Pricing.",
    subtitle: "There is a better way to shop for Azure cloud storage.",
    titleVariant: "h2",
    subtitleVariant: "h4",      
  },
  quoteForm: {
    quote_provider:"azure",
    title: "Save 60% or more on Azure cloud storage pricing from leading partners",  
    subtitle: "Let managed service providers bid for your business",
    titleVariant: "h4",
    subtitleVariant: "h5",
    refPage: "azure-storage-pricing",   
  },
  subText: {
    title: "Azure Storage Pricing",
    emphasized: "Simplified.",
    subtitle: "Let us take the complexity of Azure Storage Pricing away from you. We will take your requirements and source competitive quotes from Managed Service Providers, saving you up to 60% on Azure storage pricing. Request a quote today, it’s simple and free!",
    titleVariant: "h5",
    subtitleVariant: "h6",     
  }
};

// END DATA

const LandingPage = () => {
  return (
  <>
  <Helmet
    defaultTitle="WindRate | #1 Cloud Storage Pricing Engine"
    title={title}
    titleTemplate="%s | WindRate.com"
  />     
    <WithLandingPageLayout
      data={content}
      component={LandingPageView2}
      layout={Main}
    />
    </>
  )
};
 
 export default LandingPage;
 